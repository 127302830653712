import React from 'react';
import './App.css';
import SvgMapGen from './components/SvgMapGen';
import FileDrop from 'react-file-drop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronCircleLeft, faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'
const {parse} = require('svgson');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maps: [],
      currentMap: 0
    }
    this.fileReader = null;
  }

  handleFileRead = (e) => {
    console.log(e)
    let content = e.target.result;
    parse(content)
      .then((json) => {
        console.log(json)
        this.setState({
          maps: [
              ...this.state.maps, 
              json              
            ]
        })
      })
  }
 
  onDrop = (files) => {   
    for(let i = 0; i < files.length; i++){
      if(files[i].type !== "image/svg+xml") return;
      var fileReader = new FileReader();
      fileReader.onloadend = this.handleFileRead;
      fileReader.readAsText(files[i]);
    }
    this.setState({maps: [], currentMap: 0})
  }

  nextMap = () => {
    if(this.state.currentMap+1 === this.state.maps.length){
      this.setState({
        currentMap: 0,
      })
    } else {
      this.setState({
        currentMap: this.state.currentMap+1
      })
    }
  }

  prevMap = () => {
    if(this.state.currentMap === 0){
      this.setState({
        currentMap: this.state.maps.length-1,
      })
    } else {
      this.setState({
        currentMap: this.state.currentMap-1
      })
    }        
  }
  render() {
    let {maps,currentMap} = this.state
    return (
      <div>
        <div style={{height: "70px", background: "black", display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"center"}}>
            <h1 style={{color: "white", marginLeft: "30px"}}>Heavy Cream Map Checker</h1>    
            {maps.length > 0 && <h2 style={{color: "white", marginLeft: "30px"}}>Viewing: {currentMap+1} / {maps.length} - {maps[currentMap].children[1].children[0].value}</h2>}
          </div>
          {maps.length > 1 && <div>
            <button onClick={this.prevMap} style={{backgroundColor: "transparent", border: "none"}}><FontAwesomeIcon icon={faChevronCircleLeft} size="4x" color="white"/></button>
            <button onClick={this.nextMap} style={{backgroundColor: "transparent", border: "none"}}><FontAwesomeIcon icon={faChevronCircleRight} size="4x" color="white"/></button>
          </div>}
        </div>
        <div>
        <FileDrop onDrop={this.onDrop}>
          <div>
            {!maps[0] && <p>Drag a map here to upload</p>}
          </div>
        </FileDrop>
        </div>
        <div className="mapHolder" style={{height: "calc(100vh - 70px)", width: "100%", display:"flex", content: "", justifyContent:"center", alignItems: "center"}}>
            {maps[0] && <SvgMapGen map={maps[currentMap]}></SvgMapGen>}
        </div>
      </div>
    );
  }
}

export default App;
