import React from 'react';
import placeHolderImage from './testImg.jpg'
export default class SvgMapGen extends React.Component{
    createSvgMap(data) {
        return data.children.map((items) => {
            let child = items.children
            if(items.attributes.id === "Map_Outline" || items.attributes.id === "Cash_Wrap"){
                return child.map((item, index) => {
                   if(item.name === "path"){
                       return <path key={index} fill="#cdcdcd" d={item.attributes.d}/>
                   }
                   if(item.name === "polygon"){
                       return <polyline key={index} fill="#cdcdcd" points={item.attributes.points} />
                   }
                   if(item.name === "rect"){
                    return <rect key={index} fill="#cdcdcd" x={item.attributes.x} y={item.attributes.y} width={item.attributes.width} height={item.attributes.height} transform={item.attributes.transform}/>
                    }
                    return null;
                })
            }
            if(items.attributes.id === "Arrows"){
                return child.map((item, index) => {
                    if(item.name === "path"){
                        return <path key={index} d={item.attributes.d}/>
                    }
                    if(item.name === "line"){
                        return <line key={index} stroke="black" x1={item.attributes.x1} y1={item.attributes.y1} x2={item.attributes.x2} y2={item.attributes.y2}/>
                    }
                    if(item.name === "polyline"){
                        return <polyline key={index} points={item.attributes.points} stroke="black" strokeWidth="1" fill="none"/>
                    }
                    return null;
                })
            }
            if(items.attributes.id === "Cabinets"){
                return child.map((item, index) => {
                    if(item.name === "rect"){
                        let color = (item.attributes.transform !== undefined && item.attributes.transform.startsWith("matrix")) ? "red" : "black"
                        return <g transform={item.attributes.transform} key={index}>
                                <rect width={item.attributes.width} stroke={color} strokeWidth="1" fill="white" height={item.attributes.height} x={item.attributes.x} y={item.attributes.y}/>
                                <rect width={item.attributes.width} fill="black" height={5} x={item.attributes.x} y={item.attributes.y}/>
                            </g>    
                    }
                    return null;
                })
            }
            if(items.attributes.id === "Original_Map"){
                return <g key="original_map_alert"><rect x="10" y="10" width="120" height="50" fill="red"></rect><text x="15" y="43" font-size="20" fill="white">Original Map</text></g>
            }
            if(items.attributes.id === "Half_Cabinets"){
                return child.map((item, index) => {
                    if(item.name === "rect"){
                        let color = (item.attributes.transform !== undefined && item.attributes.transform.startsWith("matrix")) ? "red" : "black"
                        return <g transform={item.attributes.transform} key={index}>
                                <rect width={item.attributes.width} stroke={color} strokeWidth="1" fill="white" height={item.attributes.height} x={item.attributes.x} y={item.attributes.y}/>
                                <rect width={item.attributes.width} fill="black" height={5} x={item.attributes.x} y={item.attributes.y}/>
                            </g>   
                    }
                    return null;                    
                })
            }
            return null
        })
    }
    generateCabinets(data) {
        return data.children.map((items) => {
            let child = items.children
            if(items.attributes.id === "Cabinets"){
                return child.map((item, index) => {
                    if(item.name === "rect"){
                        return <g key={"cab_" + index} 
                                    height={item.attributes.height} 
                                    width={item.attributes.width} 
                                    transform={item.attributes.transform}>
                                        <image 
                                                href={placeHolderImage} 
                                                height={item.attributes.height} 
                                                width={item.attributes.width} 
                                                x={item.attributes.x} 
                                                y={item.attributes.y}
                                            />
                                    </g> 
                    }
                    return null;
                })
            } 
            return null;
        })
    }
    render(){
        let {map} = this.props;
        return (
                <div style={{height: "100%", width: "100%", display: "flex", justifyContent:"center"}}>
                    <svg height="100%" viewBox={map.attributes.viewBox}>
                        <g>
                            {this.createSvgMap(map)}
                        </g>
                    </svg>
                </div>
        )
    }
}